import React,{Component,useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import WOW from 'wowjs';


//Components 
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import VideoPopup from './../components/VideoPopup';
import BannerSlider from './../components/BannerSlider';
import PortfolioSlider from './../components/PortfolioSlider';
import ClientsSlider from './../components/ClientsSlider';
import TestimonialSlider from './../components/TestimonialSlider';
import TrendsSlider from './../components/TrendsSlider';


//Images
import bg1 from './../assets/images/background/bg1.png';
import bg2 from './../assets/images/background/bg2.jpg';
import Bg2Png from './../assets/images/background/bg2.png';
import bg3 from './../assets/images/background/bg3.jpg';
import bg22 from './../assets/images/background/bg22.jpg';

import about1 from './../assets/images/about/about1.jpg';
import about2 from './../assets/images/about/about2.jpg';
import about6 from './../assets/images/about/about6.jpg';
import about7 from './../assets/images/about/about7.jpg';
import about8 from './../assets/images/about/about8.jpg';
import about9 from './../assets/images/about/about9.jpg';
import utilityaid from './../assets/images/utilityaid.png';



//Array

const counterBlog = [
	{title: 'Delivered Goods', countnum:'528',},
	{title: 'Clients Worldwide', countnum:'444',},
	{title: 'Tons of Goods', countnum:'641',},
];



const infoSection = [
	{ iconClass:'far fa-envelope', title:'Email Address', 
		subtitle: <h5 className="m-b0">martin.edwards@uchange4better.co.uk</h5>
	},
	{ iconClass:'fas fa-phone', title:'Phone Number', 
		subtitle: <h5 className="m-b0 text-nowrap">01492 8710733</h5>
	},
	{ iconClass:'fas fa-phone', title:'Phone Number',
		subtitle: <h5 className="m-b0 text-nowrap">01268 777750  / 07888 657437</h5>
	},
];


class SocialTrading extends Component {
	componentDidMount(){
	  new WOW.WOW().init();
	}
	render(){
		return(
			<Index1 />
		)
	}
}
export default SocialTrading;



function Index1(){
	//Add split-active event
	const [splitEvent, setsplitEvent] = useState(false);
	useEffect(() => {
		const handleScroll = () => {
			setsplitEvent(window.scroll > 96);
		}
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll);
	},[]) 
	return(
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="main-slider1">
					<BannerSlider />
				</div>		

				
				<section className="content-inner-7 overflow-hidden">
					<div className="container-fluid p-lg-0">
						<PortfolioSlider />
					</div>
				</section>	
				<section className="content-inner overlay-black-dark" style={{backgroundImage: "url(" + bg3 + ")"}}>
					<div className="container">
						<div className="row about-style5">
							<div className="col-lg-6 m-b30">
								<div className="about-media aos-item " >	
									<img className="w-100 m-b10" src={about6} alt="" />
									<div className="video-box e">
										<img className="w-100" src={about7} alt="" />
									</div>
								</div>
							</div>
							<div className="col-lg-6 m-b30">
								<div className="about-content">
									<div className="section-head style-1 aos-item"  >
										<h3 className="title m-b20 text-white">Making administration easier and life simpler</h3>
									</div>
									<ul className="list-number-circle">
										<li data-text="1" className="aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
											<h3 className="text-white">Why</h3>
											<p className="text-white">By using our partnership bill validation and bureau services we will be able to check on all energy invoices for accuracy and following and queries for our customers leaving them more time to manage other aspects for their organisations. </p>
										</li>
										<li data-text="2" className="aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
											<h3 className="text-white">Benefits</h3>
											<p className="text-white">Having all the meters inside a single energy basket contract means no worrying about separate accounts; different contracts end dates and multiple suppliers. We will help cut the costs in money and staff time managing your energy needs going forward.</p>
										</li>
										<li data-text="3" className="aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
											<h3 className="text-white">Support</h3>
											<p className="text-white">Simply call us or email to start on a journey for us to help make life greener, easier and better for the future. </p>
										</li>
									</ul>
									<Link to={"./services"} className="btn btn-primary btn-icon">Our services <i className="fas fa-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="content-inner" style={{backgroundImage:"url(" + bg1 + ")" , backgroundPosition:'bottom' , backgroundSize: 'cover' }}>
					<div className="container">
						<div className="row">
							<div className="col-lg-5 m-b30">
								<div className={splitEvent ? "split-box" : "split-box split-active"}>
									<div className="about-thumb p-r15 p-md-r0">	
										<img className="w-100 m-b10" src={about8} alt="" />
									</div>
								</div>
							</div>
							<div className="col-lg-7 m-b30 align-self-center">
								<div className="about-content">
									<div className="section-head style-1 aos-item "  >
										<h2 className="title m-b20">How we can help</h2>
									</div>
									<div className="row m-b10">
										<div className="col-md-8 m-b30 aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
											<p>In terms of costs we are developing green energy baskets where all types of organisations (commercial, non-for-profit and charities) can come together and buy their energy in substantial blocks and get volume discounted energy thus cutting the costs for the individual members of the basket. These contracts are usually for 1 year to 3 years in duration.</p>
										</div>
										<div className="col-md-4">
											<ul className="list-square-box">
												<li className="aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
													<h4>Costs</h4>
													<p>Dealing With High Cost Energy </p>
												</li>
											</ul>
										</div>
									</div>

									<div className="row m-b10">
										<div className="col-md-8 m-b30 aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
											<p>All of our energy baskets will have predominantly green energy with the aim of making it 100% renewable which is fully audited to make sure all electricity is generated from renewable sources. This will be from wind, solar, biomass, hydro and wave power.  We will also provide you with a net zero energy plan and walk with you to help achieve being net zero for the future.</p>
										</div>
										<div className="col-md-4">
											<ul className="list-square-box">
												<li className="aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
													<h4>Climate change</h4>
													<p>Handling the issues of climate change</p>
												</li>
											</ul>
										</div>
									</div>


									<div className="row m-b10">
										<div className="col-md-8 m-b30 aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
											<p>Uchange with its partners uses the latest technology. E.g. a state of the art online portal where our customers can monitor all their smart meters energy consumption on an ongoing basis. This means no more manually submitting meter reads, no more having to waste time and effort visiting multiple sites to get monthly meter reads and no more surprise bills. Non normal energy usage can be spotted straight away and investigated.</p>
										</div>
										<div className="col-md-4">
											<ul className="list-square-box">
												<li className="aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
													<h4>Technology</h4>
													<p>Cutting Edge Technology</p>
												</li>
											</ul>
										</div>
									</div>


									<div className="row m-b10">
										<div className="col-md-8 m-b30 aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
											<p>By using our partnership bill validation and bureau services we will be able to check on all energy invoices for accuracy and following and queries for our customers leaving them more time to manage other aspects for their organisations.
Having all the meters inside a single energy basket contract means no worrying about separate accounts; different contracts end dates and multiple suppliers. We will help cut the costs in money and staff time managing your energy needs going forward.
Simply call us or email (details below) to start on a journey for us to help make life greener, easier and better for the future. </p>
										</div>
										<div className="col-md-4">
											<ul className="list-square-box">
												<li className="aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
													<h4>Administration</h4>
													<p>Making administration easier and life simpler</p>
												</li>
											</ul>
										</div>
									</div>

									<div className="row m-b10">
										<div className="col-md-8 m-b30 aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
											<p>Utility Aid understands that staff and volunteers alike are time-poor and with this in mind, we want to make our services more accessible and compatible with flexi/volunteering hours. Our new online switching service will enable users to get instant access to commercial utility quotations from a list of reliable credit-vetted suppliers at any time. The online service also includes a chat function where users can speak with one of our Energy Specialists online between the hours of 9-5 pm. </p>
											<a href="https://switching.utility-aid.co.uk/?source=Uchange4better&projectname=ua-utility&id=6706869c2d814f193ee38093"><img src={utilityaid} alt="" /></a>

										</div>
										<div className="col-md-4">
											<ul className="list-square-box">
												<li className="aos-item wow fadeInUp"  data-wow-delay="0.01s" data-wow-duration="1.3s">
													<h4>Utility Aid</h4>
													<p>Utility Aid’s new Switching Site</p>
												</li>
											</ul>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</section>


				<section className="content-inner">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 m-b30">
								<div className="section-head style-1 aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="400">
									<h5 className="sub-title text-primary">CONTACT US</h5>
									<h2 className="title m-b20">Get In Touch.</h2>
								</div>
								{infoSection.map((item, ind)=>( 
									<div className="icon-bx-wraper left style-2 m-b10 aos-item wow fadeInRight" data-wow-delay="0.01s" data-wow-duration="1.3s" key={ind}>
										<div className="icon-bx-sm radius bg-primary"> 
											<Link to={"#"} className="icon-cell text-white">
												<i className={item.iconClass}></i>
											</Link> 
										</div>
										<div className="row align-items-center w-100">
											<div className="col-sm-6">
												<h4 className="dz-title mb-sm-0 mb-2">{item.title}</h4>
											</div>
											<div className="col-sm-6">
												{item.subtitle}
											</div>
										</div>
									</div>
								))}
							</div>

						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	)
} 
export {Index1};